<template>
  <div class="body">
    <!-- 抗原缴费记录详情 -->
    <div class="bar-code">
      <div class="bar-code-box">
        <canvas id="canvas"></canvas>
      </div>
    </div>
    <div class="pay-record">
      <div class="pay-record-item">
        <span class="pay-record-item-label">就诊人姓名：</span
        >{{ recordDetail.name }}
      </div>
      <div class="pay-record-item">
        <span class="pay-record-item-label">检查项目：</span
        >{{ recordDetail.lableName }}
      </div>
      <div
        class="pay-record-item"
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
      >
        <span class="pay-record-item-label">支付单号：</span
        ><span>{{ recordDetail.hisOrderId }}</span>
      </div>
      <div class="pay-record-item">
        <span class="pay-record-item-label">支付时间：</span
        >{{
          recordDetail.changeTime == null
            ? recordDetail.changeTime
            : recordDetail.changeTime.split("_")[0]
        }}
      </div>
      <div class="pay-record-item">
        <span class="pay-record-item-label">支付金额：</span
        ><span style="color: #ee0a24">￥{{ recordDetail.amount }}</span>
      </div>
      <div class="pay-record-item">
        <span class="pay-record-item-label">支付方式：</span
        >{{ recordDetail.type }}
      </div>
      <div class="pay-record-item">
        <span class="pay-record-item-label">支付状态：</span
        >{{ payState[recordDetail.status] }}
      </div>
      <div class="pay-record-item">
        <span class="pay-record-item-label">检验结果：</span
        >{{ recordDetail.result ? recordDetail.result : "-" }}
      </div>
      <div class="pay-record-item" v-if="recordDetail.status == 6">
        <span class="pay-record-item-label">退款时间：</span
        >{{
          recordDetail.refundTime == null
            ? recordDetail.refundTime
            : recordDetail.changeTime.split("_")[0]
        }}
      </div>
    </div>
    <van-button
      plain
      type="danger"
      style="width: 9rem"
      v-if="!recordDetail.result && recordDetail.status == 1"
      @click="handleRefund"
      >申请退款</van-button
    >
    <van-dialog
      v-model="showDialog"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h3>提示</h3>
      </template>
      <template #default>
        <p>确认申请退款吗？</p>
        <van-button class="cancle-btn" @click="showDialog = false"
          >取消</van-button
        >
        <van-button type="primary" class="confirm-btn" @click="submit"
          >确认</van-button
        >
      </template>
    </van-dialog>
  </div>
</template>

<script>
import JsBarcode from "jsbarcode";
import { Toast } from "vant";
export default {
  name: "antigenDectectionRecordDtail",
  data() {
    return {
      recordDetail: {
        id: "",
        name: "",
        lableName: "",
        hisOrderId: "",
        transactionId: "",
        changeTime: "",
        amount: "",
        type: "",
        status: "",
        orderId: "",
        receiveStatus: true,
        receiveTime: "",
      },
      payState: {
        0: "未支付",
        1: "已支付",
        2: "待审核",
        3: "退款中",
        4: "已关闭",
        5: "已取消",
        6: "已退款",
        7: "拒绝退款",
        8: "支付中",
        9: "异常",
      },
      showDialog: false,
    };
  },
  methods: {
    barCode() {
      JsBarcode("#canvas", this.recordDetail.hisOrderId, {
        lineColor: "#000", //线条颜色
        background: "transparent", //设置条形码的背景
        width: 4, //线宽
        height: 130, //条码高度
        fontSize: 35, //设置文本的大小
        displayValue: true, //是否显示文字信息
      });
    },
    getDataList() {
      let postData = {
        id: this.$route.query.id,
      };
      this.$http.get("/api/antigen/info", postData).then((result) => {
        this.recordDetail = result.data;
        this.barCode();
      });
    },
    // 申请退款
    handleRefund() {
      this.showDialog = true;
    },
    submit() {
      let postData = {
        id: this.recordDetail.hisOrderId,
      };
      this.$http
        .get("/api/antigen/refund", postData)
        .then((result) => {
          Toast({
            message: "已申请退款",
            duration: 500,
            onClose: () => {
              this.$router.push("/antigenDectectionPayment");
            },
          });
        })
        .catch((err) => {
          Toast.fail({ message: err.response.data });
        });
      this.showDialog = false;
    },
  },
  mounted() {
    this.getDataList();
  },
};
</script>

<style scoped>
.body {
  width: 100%;
  height: 100%;
}
.bar-code {
  height: 150px;
  font-size: 14px;
  overflow: hidden;
}
.bar-code canvas {
  width: 8rem;
  height: 100px;
}
.bar-code-box {
  height: 120px;
  line-height: 20px;
  margin: auto;
  margin-top: 20px;
}
.pay-record {
  width: 9.5rem;
  background-color: #ffff;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 20px;
  padding: 5px;
}
.pay-record-item {
  font-size: 0.4rem;
  line-height: 26px;
  text-align: left;
}
.pay-record-item-label {
  width: 2.8rem;
  display: inline-block;
  text-align: right;
  margin-right: 5px;
}

.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-top: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}

.dialog-title {
  margin-bottom: 0.3rem;
}
.cancle-btn {
  width: 40%;
  height: 40px;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}
</style>
